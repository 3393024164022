import moment               from 'moment'

import React, { useEffect, useState }                from 'react'

import { MoreHorizontal }   from 'react-feather'

import { 
    DropdownItem, 
    DropdownMenu, 
    DropdownToggle, 
    UncontrolledDropdown 
}                           from 'reactstrap'

import { Can }              from '../../../utility/context/Can'
import { getUserData }      from '../../../utility/Utils'
import toast from 'react-hot-toast'
import { isDraft } from '@reduxjs/toolkit'

const TasklistDropdown = ({
    data,
    getData,
    reportData,
    headerReport,
    setShowForm,
    setUpModalEdit,
    handleAcceptTask,
    setUpModalApprovement,
    setUpModalAcceptTask,
    setUpModalTrackStatus,
    setUpModalEditHistory,
    setUpModalAcceptPerpanjangan,
    setUpModalRequestPerpanjangan,
}) => {

    console.log('data', data)
    
	const [showFinisihReport, setShowFinisihReport] = useState(false)

    const checkShowFinsihReport = (item) => {
		let status = false
		
		if (getUserData().user?.is_superuser) {
			status = true
		} else {
			if (Array.isArray(item?.memerintahkan)) {
				if (item?.memerintahkan?.length > 0) {
					if (parseInt(item?.memerintahkan[0]) === getUserData()?.user_id) {
						status = true
					}
				}
			} else {
				if (parseInt(item?.memerintahkan) === getUserData()?.user_id) {
					status = true
				}
			}
		}

		return status
	}

	useEffect(() => {
		setShowFinisihReport(checkShowFinsihReport(data))
	}, [data])

  return (
    <div>
        <UncontrolledDropdown className=' w-100 d-flex justify-content-center'>
            <DropdownToggle  
                tag         = 'a' 
                className   = 'nav-link' 
            >
                <MoreHorizontal />
            </DropdownToggle>
            <DropdownMenu>
                {
                    (getUserData()?.role_id !== 2 || getUserData()?.user?.is_superuser) && (data?.last_track_status?.id === 4 || data?.last_track_status?.id === 1 || data?.last_track_status?.id === 5) && 
                    <>
                        <DropdownItem 
                            onClick     = {() => { setUpModalEdit(data) }}
                            className   = 'w-100 h-100' 
                        >
                            Edit
                        </DropdownItem>
                    </>
                }
                <DropdownItem 
                    tag     = 'a'
                    onClick = {() => setUpModalTrackStatus(data)} 
                >
                    Lacak Proses
                </DropdownItem>
                <DropdownItem 
                    onClick     = {() => { setUpModalEditHistory(data) }}
                    className   = 'w-100 h-100' 
                >
                    Riwayat Penyuntingan
                </DropdownItem>
                {
                    ((moment().unix() > moment(data?.expired_date).unix())) && (data?.last_track_status?.id !== 7) &&
                        <DropdownItem 
                            onClick     = {() => { setUpModalRequestPerpanjangan(data) }}
                            className   = 'w-100 h-100' 
                        >
                            Ajukan Perpanjangan
                        </DropdownItem>
                }

                {
                    ((data?.last_track_status?.id && data?.last_track_status?.id === 8) && (getUserData()?.role_id !== 2)) &&
                        <DropdownItem 
                            onClick     = {() => { setUpModalAcceptPerpanjangan(data) }}
                            className   = 'w-100 h-100' 
                        >
                            Setujui Perpanjangan
                        </DropdownItem>
                }
            
                {
                    ((getUserData()?.role_id === 1 || getUserData()?.role_id === 4 || getUserData()?.user?.is_superuser) && data?.last_track_status?.id && data?.last_track_status?.id >= 2) &&
                    <>
                        {
                            data?.last_track_status?.id === 7 &&
                                <DropdownItem 
                                    onClick     = {() => { setUpModalAcceptTask(data) }}
                                    className   = 'w-100 h-100' 
                                >
                                    Lengkapi Berkas
                                </DropdownItem>
                        }
                    </>
                }
                {
                (
                    reportData?.reportTypeId === "1"  ||
                    reportData?.reportTypeId === "3"
                ) &&
                    <>
                        {
                            data?.last_track_status?.id === 2 &&
                            (
                                getUserData()?.role_id === 1 || 
                                getUserData()?.role_id === 4 || 
                                getUserData()?.user?.is_superuser ||
                                showFinisihReport
                            ) &&
                            <>
                                <DropdownItem className='w-100 h-100' onClick={() => { 
                                    const handleToastSuccess = () => {
                                        toast.success("Berhasil Selesaikan Surat")
                                        getData(reportData?.reportTypeId, headerReport)
                                    }

                                    handleAcceptTask(reportData, data?.report_id, 7, handleToastSuccess)
                                }}>Selesaikan Surat</DropdownItem>
                            </>
                        }
                    </>
            }
            {
                data?.is_draft === false ? (
                    getUserData()?.user?.is_superuser && reportData?.reportTypeId !== "2" ? (
                        (data?.last_track_status?.id === 1 || data?.last_track_status?.id === 5) &&
                        <>
                            {/* <DropdownItem divider /> */}
                            <DropdownItem className='w-100 h-100' onClick={() => { setUpModalApprovement(data) }}>Setujui Surat</DropdownItem>
                        </>
                    ) : getUserData()?.role_id === 2 && reportData?.reportTypeId !== "2" ? (
                            (data?.last_track_status?.id === 1 || data?.last_track_status?.id === 5) && (
                                <DropdownItem className='w-100 h-100' onClick={() => { setUpModalApprovement(data) }}>Setujui Surat</DropdownItem>
                            )
                        )
                    : null
                ) : null
            }
            </DropdownMenu>
        </UncontrolledDropdown>
    </div>
  )
}

export default TasklistDropdown