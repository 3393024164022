import { Input, InputGroup, InputGroupText } from "reactstrap"
import { Search } from "react-feather"

const NavbarSearch = () => {
    return (
        <>
            <div className="w-50 d-flex align-items-center">
            {/* <InputGroup className='input-group-merge ms-1 w-501'>
                <InputGroupText className='round'>
                  <Search className='text-muted' size={14} />
                </InputGroupText>
                <Input
                //   value={query}
                  className='round'
                  placeholder='Pencarian'
                //   onChange={handleFilter}
                />
            </InputGroup> */}
            </div>
        </>
    )
}

export default NavbarSearch