import moment                       from "moment"
import { useContext, useEffect }    from "react"
import { useNavigate, Link }        from "react-router-dom"
import { Table }                    from "reactstrap"
import { ReportContext }            from "../../../context/reportContext"

const styletd = "p-1 mb-1 align-top bg-white"
const DetailReportPreview = ({
    data
}) => {
    const navigate  = useNavigate()

    const {
        dataSelected 
    } = useContext(ReportContext)

    const getValue = (key, type, subKey1, subKey2) => {
        if (type === "text") {
            return dataSelected[key] ? dataSelected[key] : "-"
        } else if (type === "get_value_lvl1") {
            return dataSelected[key] ? dataSelected[key][subKey1] : "-"
        } else if (type === "get_value_lvl2") {
            return dataSelected[key] ? dataSelected[key][subKey1] ? dataSelected[key][subKey1][subKey2] : "-" : "-"
        } else if (type === "date") {
            return dataSelected[key] ? moment.unix(dataSelected[key]).format("DD-MM-YYYY") : "-"
        }
    }

    const handleStringToLink = (text, action) => {
        const string_ = text

        const parts = string_.split(' ')
        if (parts.length > 0) {
            const result = parts[0]

            const subParts = result.split('.')

            if (subParts.length === 3) {
                return (
                    <Link 
                        to      = {`/${subParts[0]?.toLowerCase()}${subParts[1]?.toLowerCase()}/${subParts[2]}?&report_id=${dataSelected?.report_id}&type=todo&action=${action}`} 
                        onClick = {() => { 
                            setDataSelected(null)
                        }}
                    >
                        {string_}
                    </Link>
                )
            } else {
                return (
                    <Link 
                        to      = {`/${subParts[0]?.toLowerCase()}/${subParts[1]}?report_id=${dataSelected?.report_id}&type=todo&action=create`} 
                        onClick = {() => { 
                            setDataSelected(null)
                        }}
                    >
                        {string_}
                    </Link>
                )
            }
        }

        return string_
    }

    const handleStringToLinkRequirement = (text, reportId) => {
        const string_ = text

        const parts = string_.split(' ')
        if (parts.length > 0) {
            const result = parts[0]

            const subParts = result.split('.')

            if (subParts.length === 3) {
                const reportType = `${subParts[0]?.toLowerCase()}${subParts[1]?.toLowerCase()}${subParts[2]}`

                if (reportId) {
                    return (
                        <a 
                            href    = {`/${subParts[0]?.toLowerCase()}${subParts[1]?.toLowerCase()}/${subParts[2]}?&report_id=${reportId}&type=requirement&action=${reportType === "rin3" ? "detail" : "preview"}`}
                            target  = "_blank"
                        >
                            {string_}
                        </a>
                    )
                } else {
                    return (
                        <a 
                            href    = {`/${subParts[0]?.toLowerCase()}${subParts[1]?.toLowerCase()}/${subParts[2]}`}
                            target  = "_blank"
                        >
                            {string_}
                        </a>
                    )
                }

            } else {

                if (reportId) {
                    return (
                        <a 
                            href    = {`/${subParts[0]?.toLowerCase()}/${subParts[1]}?&report_id=${reportId}&type=requirement&action=preview`} 
                            target  = "_blank"
                        >
                            {string_}
                        </a>
                    )
                } else {
                    return (
                        <a 
                            href    = {`/${subParts[0]?.toLowerCase()}/${subParts[1]}`} 
                            target  = "_blank"
                        >
                            {string_}
                        </a>
                    )
                }

            }
        }

        return string_
    }

    return (
        <>
            {
                dataSelected !== null &&
                <small>

                    <Table className="table-sm text-sm">
                        <thead>
                            <tr className="p-5">
                                <td className={`${styletd}`}><strong>No. Surat</strong></td>
                                <td className={`${styletd}`}>:</td>
                                <td className={`${styletd}`}>{getValue("nomor_surat", "text")}</td>
                            </tr>
                            <tr>
                                <td className={`${styletd}`}><strong>Berkas Terkait</strong></td>
                                <td className={`${styletd}`}></td>
                                <td className={`${styletd}`}></td>
                            </tr>
                            <tr>
                                <td className={`${styletd}`}><strong>Lampiran Pendukung</strong></td>
                                <td className={`${styletd}`}>:</td>
                                <td className={`${styletd}`}>
                                    <div 
                                        className="p-0 m-0"
                                    >
                                        {
                                            dataSelected?.extra_data?.requirements?.data?.map((dt, idx) => {
                                                return (
                                                    <>
                                                        <div>
                                                            {
                                                                dt?.reports?.length > 0 ?
                                                                    handleStringToLinkRequirement(dt?.name, dt?.reports[0])
                                                                :
                                                                    null
                                                            }
                                                        </div>
                                                        <ul
                                                            className   = "px-2"
                                                        >
                                                            {
                                                                dt.report_source?.map((source) => {
                                                                    return (
                                                                        source?.reports?.length > 0 &&
                                                                        <li>
                                                                            {
                                                                                handleStringToLinkRequirement(source?.name, source?.reports[0])
                                                                            }
                                                                        </li>
                                                                    )
                                                                })
                                                            }
                                                        </ul>
                                                        
                                                    </>
                                                )
                                            })
                                        }

                                    </div>
                                </td>
                            </tr>
                            {
                                console.log("dataSelected", dataSelected)
                            }
                            <tr>
                                <td className={`${styletd}`}><strong>Berkas Yang Harus Dikerjakan</strong></td>
                                <td className={`${styletd}`}>:</td>
                                <td className={`${styletd}`}>
                                    <div className="p-0 m-0">
                                        {
                                            dataSelected?.extra_data?.todos?.data?.map((dt, idx) => {
                                                return (
                                                    <>
                                                        <div>
												            {handleStringToLink(dt?.name, "create")}
                                                        </div>
                                                    </>
                                                )
                                            })
                                        }
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td className={`${styletd}`}><strong>Tercatat di</strong></td>
                                <td className={`${styletd}`}>:</td>
                                <td className={`${styletd}`}>
                                    <div className="p-0 m-0">
                                        {
                                            dataSelected?.extra_data?.todos?.linked?.map((dt, idx) => {
                                                return (
                                                    <>
                                                         <div>
                                                            {handleStringToLink(dt?.report_type, 'read')}
                                                        </div>
                                                    </>
                                                )
                                            })
                                        }
                                    </div>
                                </td>
                            </tr>

                            <tr className="p-5">
                                <td className={`${styletd}`}><strong>Judul</strong></td>
                                <td className={`${styletd}`}>:</td>
                                <td className={`${styletd}`}>{getValue("judul", "text")}</td>
                            </tr>
                            <tr className="p-5">
                                <td className={`${styletd}`}><strong>Bidang</strong></td>
                                <td className={`${styletd}`}>:</td>
                                <td className={`${styletd}`}>{getValue("bidang", "get_value_lvl1", "name")}</td>
                            </tr>
                            <tr className="p-5">
                                <td className={`${styletd}`}><strong>Sektor</strong></td>
                                <td className={`${styletd}`}>:</td>
                                <td className={`${styletd}`}>{getValue("sektor", "get_value_lvl1", "name")}</td>
                            </tr>
                            <tr className="p-5">
                                <td className={`${styletd}`}><strong>Tanggal Dikeluarkan</strong></td>
                                <td className={`${styletd}`}>:</td>
                                <td className={`${styletd}`}>{getValue("tanggal", "date")}</td>
                            </tr>
                            <tr className="p-5">
                                <td className={`${styletd}`}><strong>Tanggal Kadaluarsa</strong></td>
                                <td className={`${styletd}`}>:</td>
                                <td className={`${styletd}`}>{getValue("expired_date", "text")}</td>
                            </tr>
                            <tr className="p-5">
                                <td className={`${styletd}`}><strong>Penandatangan</strong></td>
                                <td className={`${styletd}`}>:</td>
                                <td className={`${styletd}`}>{getValue("penandatangan", "get_value_lvl1", "name")}</td>
                            </tr>
                            <tr className="p-5">
                                <td className={`${styletd}`}><strong>Pangkat/ Jabatan</strong></td>
                                <td className={`${styletd}`}>:</td>
                                <td className={`${styletd}`}>{getValue("penandatangan", "get_value_lvl2", "grade", "name")} / {getValue("penandatangan", "get_value_lvl2", "position", "name")}</td>
                            </tr>
                            <tr className="p-5">
                                <td className={`${styletd}`}><strong>Dibuat Oleh</strong></td>
                                <td className={`${styletd}`}>:</td>
                                <td className={`${styletd}`}>{getValue("created_by", "get_value_lvl1", "name")}</td>
                            </tr>
                            <tr className="p-5">
                                <td className={`${styletd}`}><strong>Dibuat Pada</strong></td>
                                <td className={`${styletd}`}>:</td>
                                <td className={`${styletd}`}>{getValue("created_at", "text")}</td>
                            </tr>
                            <tr className="p-5">
                                <td className={`${styletd}`}><strong>Diupdate Oleh</strong></td>
                                <td className={`${styletd}`}>:</td>
                                <td className={`${styletd}`}>{getValue("updated_by", "get_value_lvl1", "name")}</td>
                            </tr>
                            <tr className="p-5">
                                <td className={`${styletd}`}><strong>Diupdate Pada</strong></td>
                                <td className={`${styletd}`}>:</td>
                                <td className={`${styletd}`}>{getValue("updated_at", "text")}</td>
                            </tr>
                            <tr className="p-5">
                                <td className={`${styletd}`}><strong>Status Dokumen</strong></td>
                                <td className={`${styletd}`}>:</td>
                                <td className={`${styletd}`}>{getValue("last_track_status", "get_value_lvl1", "status")}</td>
                            </tr>
                            <tr className="p-5">
                                <td className={`${styletd}`}><strong>Status Penyelesaian</strong></td>
                                <td className={`${styletd}`}>:</td>
                                <td className={`${styletd}`}>-</td>
                            </tr>
                        </thead>
                    </Table>
                </small>
            }
        </>
    )
}

export default DetailReportPreview
