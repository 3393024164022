// ** React Imports
import { useState, useEffect, useContext } from 'react'
import { useLocation } from 'react-router-dom'

// ** Store & Actions
import { useSelector, useDispatch } from 'react-redux'
import { handleMenuCollapsed, handleContentWidth, handleMenuHidden } from '@store/layout'

// ** Third Party Components
import classnames from 'classnames'
import { ArrowUp, Navigation } from 'react-feather'

// ** Reactstrap Imports
import { Navbar, Button } from 'reactstrap'

// ** Configs
import themeConfig from '@configs/themeConfig'

// ** Custom Components
import Customizer from '@components/customizer'
import ScrollToTop from '@components/scrolltop'
import FooterComponent from './components/footer'
import NavbarComponent from './components/navbar'
import SidebarComponent from './components/menu/vertical-menu'

// ** Custom Hooks
import { useRTL } from '@hooks/useRTL'
import { useSkin } from '@hooks/useSkin'
import { useLayout } from '@hooks/useLayout'
import { useNavbarType } from '@hooks/useNavbarType'
import { useFooterType } from '@hooks/useFooterType'
import { useNavbarColor } from '@hooks/useNavbarColor'
import { useRouterTransition } from '@hooks/useRouterTransition'

// ** Styles
import '@styles/base/core/menu/menu-types/vertical-menu.scss'
import '@styles/base/core/menu/menu-types/vertical-overlay-menu.scss'
import ButtonPopupChat from './components/button-popup-chat'

// ** Context
import { GlobalContext } from '../../context/globalContext'
import { ReportContext } from '../../context/reportContext'

const VerticalLayout = props => {
	// ** Props
	const { menu, navbar, footer, children, menuData } = props

	// ** Context
	const { sidebarCount } = useContext(GlobalContext)

	const {
		viewerCount
	} = useContext(ReportContext)

	// ** Hooks
	const [isRtl, setIsRtl] = useRTL()
	const { skin, setSkin } = useSkin()
	const { navbarType, setNavbarType } = useNavbarType()
	const { footerType, setFooterType } = useFooterType()
	const { navbarColor, setNavbarColor } = useNavbarColor()
	const { layout, setLayout, setLastLayout } = useLayout()
	const { transition, setTransition } = useRouterTransition()

	// ** States
	const [isMounted, setIsMounted] = useState(false)
	const [menuVisibility, setMenuVisibility] = useState(false)
	const [windowWidth, setWindowWidth] = useState(window.innerWidth)
	const [finalMenu, setFinalMenu] = useState([])

	// ** Vars
	const dispatch = useDispatch()
	const layoutStore = useSelector(state => state.layout)

	// ** Update Window Width
	const handleWindowWidth = () => {
		setWindowWidth(window.innerWidth)
	}

	// ** Vars
	const location = useLocation()
	const isHidden = layoutStore.menuHidden
	const contentWidth = layoutStore.contentWidth
	const menuCollapsed = layoutStore.menuCollapsed

	// ** Toggles Menu Collapsed
	const setMenuCollapsed = val => dispatch(handleMenuCollapsed(val))

	// ** Handles Content Width
	const setContentWidth = val => dispatch(handleContentWidth(val))

	// ** Handles Content Width
	const setIsHidden = val => dispatch(handleMenuHidden(val))

	// ** Set Extra Count Badge For IN, LIN, DIN, and RIN
	const setExtraCountBadge = () => {

		if (Array.isArray(menuData) && Array.isArray(sidebarCount)) {

			// Membuat objek untuk mengakses sidebarCount dengan menggunakan report_type_id sebagai kunci
			const sidebarCountMap = {}
			const reportId = [1, 2, 3, 21]
			sidebarCount?.forEach(item => {
				if (reportId.includes(item.report_type_id)) {
					sidebarCountMap[item.report_type_id] = item.count
				}
			})

			// Memperbarui menuData dengan count dari sidebarCount sesuai id yang sesuai
			menuData?.forEach(menuItem => {
				if ("children" in menuItem) {
					menuItem.children.forEach(childItem => {
						const count = viewerCount[childItem.id]
						if (count !== undefined) {
							childItem.badge = 'danger'
							childItem.badgeText = count
						}
					})
				}
			})

			setFinalMenu(menuData)
		}

	}

	//** This function will detect the Route Change and will hide the menu on menu item click
	useEffect(() => {
		if (menuVisibility && windowWidth < 1200) {
			setMenuVisibility(false)
		}
	}, [location])

	//** Sets Window Size & Layout Props
	useEffect(() => {
		if (window !== undefined) {
			window.addEventListener('resize', handleWindowWidth)
		}
	}, [windowWidth])

	//** ComponentDidMount
	useEffect(() => {
		setIsMounted(true)
		setExtraCountBadge()

		return () => setIsMounted(false)
	}, [sidebarCount])

	useEffect(() => {
		setIsMounted(true)
		setExtraCountBadge()

		return () => setIsMounted(false)
	}, [viewerCount])

	// ** Vars
	const footerClasses = {
		static: 'footer-static',
		sticky: 'footer-fixed',
		hidden: 'footer-hidden'
	}

	const navbarWrapperClasses = {
		floating: 'navbar-floating',
		sticky: 'navbar-sticky',
		static: 'navbar-static',
		hidden: 'navbar-hidden'
	}

	const navbarClasses = {
		floating: contentWidth === 'boxed' ? 'floating-nav container-xxl' : 'floating-nav',
		sticky: 'fixed-top',
		static: 'navbar-static-top',
		hidden: 'd-none'
	}

	const bgColorCondition = navbarColor !== '' && navbarColor !== 'light' && navbarColor !== 'white'

	if (!isMounted) {
		return null
	}
	return (
		<div
			className={classnames(
				`wrapper vertical-layout ${navbarWrapperClasses[navbarType] || 'navbar-floating'} ${footerClasses[footerType] || 'footer-static'
				}`,
				{
					// Modern Menu
					'vertical-menu-modern': windowWidth >= 1200,
					'menu-collapsed': menuCollapsed && windowWidth >= 1200,
					'menu-expanded': !menuCollapsed && windowWidth > 1200,

					// Overlay Menu
					'vertical-overlay-menu': windowWidth < 1200,
					'menu-hide': !menuVisibility && windowWidth < 1200,
					'menu-open': menuVisibility && windowWidth < 1200
				}
			)}
			{...(isHidden ? { 'data-col': '1-column' } : {})}
		>
			{!isHidden ? (
				<SidebarComponent
					skin={skin}
					menu={menu}
					menuData={finalMenu}
					menuCollapsed={menuCollapsed}
					menuVisibility={menuVisibility}
					setMenuCollapsed={setMenuCollapsed}
					setMenuVisibility={setMenuVisibility}
				/>
			) : null}

			<Navbar
				expand='lg'
				container={false}
				light={skin !== 'dark'}
				dark={skin === 'dark' || bgColorCondition}

				color={bgColorCondition ? navbarColor : undefined}
				className={classnames(
					`header-navbar navbar align-items-center ${navbarClasses[navbarType] || 'floating-nav'} navbar-shadow`
				)}
			>
				<div className='navbar-container d-flex content'>
					{navbar ? (
						navbar({ skin, setSkin, setMenuVisibility })
					) : (
						<NavbarComponent
							isHidden={isHidden}
							setIsHidden={setIsHidden}
							skin={skin}
							setSkin={setSkin}
						/>
					)}
				</div>
			</Navbar>
			{children}

			{/* Vertical Nav Menu Overlay */}
			<div
				className={classnames('sidenav-overlay', {
					show: menuVisibility
				})}
				onClick={() => setMenuVisibility(false)}
			></div>
			{/* Vertical Nav Menu Overlay */}

			{themeConfig.layout.customizer === true ? (
				<Customizer
					skin={skin}
					isRtl={isRtl}
					layout={layout}
					setSkin={setSkin}
					setIsRtl={setIsRtl}
					isHidden={isHidden}
					setLayout={setLayout}
					footerType={footerType}
					navbarType={navbarType}
					transition={transition}
					setIsHidden={setIsHidden}
					themeConfig={themeConfig}
					navbarColor={navbarColor}
					contentWidth={contentWidth}
					setTransition={setTransition}
					setFooterType={setFooterType}
					setNavbarType={setNavbarType}
					setLastLayout={setLastLayout}
					menuCollapsed={menuCollapsed}
					setNavbarColor={setNavbarColor}
					setContentWidth={setContentWidth}
					setMenuCollapsed={setMenuCollapsed}
				/>
			) : null}
			<footer
				className={classnames(`footer footer-light ${footerClasses[footerType] || 'footer-static'}`, {
					'd-none': footerType === 'hidden'
				})}
			>
				{footer ? footer : <FooterComponent footerType={footerType} footerClasses={footerClasses} />}
			</footer>

			{themeConfig.layout.scrollTop === true ? (
				<div className='scroll-to-top'>
					<ScrollToTop showOffset={300} className='scroll-top d-block'>
						<Button className='btn-icon' color='primary'>
							<ArrowUp size={14} />
						</Button>
					</ScrollToTop>
				</div>
			) : null}
		</div>
	)
}

export default VerticalLayout
