const statusPenyelesaianOptionLin5 = [
    {
        value   : "Dikirim Ke Instansi Lain",
        label   : "Selesai - Dikirim Ke Instansi Lain"
    },
    {
        value   : "Ditutup",
        label   : "Selesai - Ditutup"
    },
    {
        value   : "Diserahkan Ke Bidang Lain",
        label   : "Selesai - Diserahkan Ke Bidang Lain"
    },
    {
        value   : "Draft",
        label   : "Selesai - Draft"
    },
]

export default statusPenyelesaianOptionLin5