import { useEffect, useState }              from "react"
import { Nav, NavItem, NavLink }            from "reactstrap"
import { useParams }                        from "react-router-dom"

import UserManagementConfiguration          from "./user-management"
import RoleConfiguration                    from "./role"
import UserActivityConfiguration            from "./user-activity"
import WorkunitConfiguration                from "./workunit"
import RecyleBinConfiguration               from "./recycle-bin"
import EvaluationIndicatorConfiguration     from "./evaluation-indicator"
import PositionConfiguration                from "./position"
import { getUserData }                     from "../../utility/Utils"
import NewFile                             from "./new-file"

const CongurationTabMenu = () => {

    const params                = useParams()
    const type                  = params.type
    const [active, setActive]   = useState(0)

    const toggle = tab => {
        if (active !== tab) {
            setActive(tab)
        }
    }

    const listTabs = [
        {
            id: 1,
            name: "Manajemen Pengguna"
        }, {
            id: 2,
            name: "Role"
        }, {
            id: 3,
            name: "Log Aktivitas"
        }, {
            id: 4,
            name: "Jabatan"
        }, {
            id: 5,
            name: "Satuan Kerja"
        }, {
            id: 6,
            name: "Recycle BIN"
        }, {
            id: 7,
            name: "Indikator Penilaian"
        },  {
            id: 8,
            name: "Berkas Baru"
        }
    ]

    const [tabs, setTabs] = useState()

    const renderContent = () => {
        if (active === 1) {
            return <UserManagementConfiguration />
        } else if (active === 2) {
            return <RoleConfiguration />
        } else if (active === 3) {
            return <UserActivityConfiguration />
        } else if (active === 4) {
            return <PositionConfiguration />
        } else if (active === 5) {
            return <WorkunitConfiguration />
        } else if (active === 6) {
            return <RecyleBinConfiguration />
        } else if (active === 7) {
            return <EvaluationIndicatorConfiguration />
        } else if (active === 8) {
            return <NewFile />
        }
    }

    useEffect(() => {
        if (type === "user-management") {
            setActive(1)
        } else if (type === "role") {
            setActive(2)
        } else if (type === "log-activity") {
            setActive(3)
        } else if (type === "position") {
            setActive(4)
        } else if (type === "workunit") {
            setActive(5)
        } else if (type === "recycle-bin") {
            setActive(6)
        } else if (type === "evaluation-indicator") {
            setActive(7)
        } else if (type === "new-file") {
            setActive(8)
        }
    }, [type])

    useEffect(() => {
        const admintabs    = [1, 2, 3, 4, 5, 6, 7, 8]
        const nonAdminTabs = [3, 6, 8]

        const selected     = []
        listTabs.map((dt) => {
            if (getUserData()?.user?.is_superuser) {
                if (admintabs.find((id) => id === dt.id)) {
                    selected.push(dt)
                }
            } else {
                if (nonAdminTabs.find((id) => id === dt.id)) {
                    selected.push(dt)
                }
            }
        })

        setTabs(selected)

        if (selected?.length > 0) {
            setActive(selected[0].id)
        }
        

        // if (getUserData()?.user?.is_superuser) {
        //     setTabs([
        //         ...tabs
        //     ])
        //     setActive(1)
        // } else {
        //     setActive(2)
        // }
    }, [])

    return (
        <>
            <Nav 
                className="border-bottom"
                tabs fill
            >
                {
                    tabs?.map((item, index) => {
                        return (
                            <NavItem key = {`configuration_tab_${index}`}>
                                <NavLink
                                    active={active === item.id}
                                    onClick={() => {
                                        toggle(item.id)
                                    }}
                                >
                                    {item.name}
                                </NavLink>
                            </NavItem>
                        )
                    })
                }
            </Nav>

            {renderContent()}
        </>
    )
}

export default CongurationTabMenu