import { 
    useState,
    createContext,
    useContext,
}                                                               from "react"

//API
import DashboardAPI                                             from "../service/pages/dashboard"
import reportAPI                                                from "../service/report"


import moment                                                   from "moment"
import { ReportContext }                                        from "./reportContext"
import toast from "react-hot-toast"
import FileManagementAPI from "../service/pages/file-management"

//Context
const DashboardContext  = createContext(null)

const DashboardProvider = ({ children }) => {

    const {
        setUpModalPreview,
        getDataById,
        getLastStatusApprovement,
    }                                                           = useContext(ReportContext)

    const [selectReport, setSelectReport]                   = useState(null)


    //State
    const [listData, setListData]                               = useState([])
    const [calendarData, setCalendarData]                       = useState(null)
    const [isCalendarLoading, setIsCalendarLoading]             = useState(false)

    //widget revision data
    const [revisionData, setRevisionData]                       = useState(null)
    const [isRevisionLoading, setIsRevisionLoading]             = useState(false)

    //Widget Report Count
    const [listReport, setListReport]                           = useState(null)
    const [listReportLoading, setListReportLoading]             = useState(false)
    const [listReportPaginate, setListReportPaginate]           = useState({})

    // Widget Report Daily
    const [listReportDaily, setListReportDaily]                 = useState(null)
    const [listReportDailyPaginate, setListReportDailyPaginate] = useState({})

    const [listReportDailyLoading, setListReportDailyLoading]   = useState(false)

     // Widget Result Report 
     const [listResultReport, setListResultReport]                 = useState(null)
     const [listResultReportPaginate, setListResultReportPaginate]  = useState({})

     const [listResultReportLoading, setListResultReportLoading]   = useState(false)

    //  Widget Create File
    const [dataCategory, setDataCategory]                 = useState(null)
    const [dataCategoryFile, setDataCategoryFile]         = useState(null)

     const [reportDataId, setReportdataId]               = useState("1")

     const [reportData, setReportdata]                   = useState({
        report          : "IN.1",
        reportType      : "IN",
        reportTitle     : "Surat Perintah Tugas",
        reportTypeId    : "1"
    })

    const getDashboardStatus = (data) => {
        const params = {
            report_status : data?.report_status ? data?.report_status : null
        }
        DashboardAPI.getDashboardStatus(params)
            .then((res) => {
                setListData(res.results)
            })
    }

    const colors = ['#007bff', '#28a745', '#fd7e14', '#e83e8c', '#6f42c1', '#522546']

    const getRandomColor = () => {
        const randomIndex = Math.floor(Math.random() * colors.length)
        return colors[randomIndex]
    }

    const getCalendarData = async(activeMonth, activeYear) => {

        try {
            setCalendarData(null)
            setIsCalendarLoading(true)

            const params = {
                data_type : 'calendar',
                month     : activeMonth ? activeMonth : moment().month() + 1,
                year      : activeYear ? activeYear : moment().year(),
                page_size : 1000
            }
    
            const calendarData_ = await DashboardAPI.getDashboarData(params)
    
            const results_ = await Promise.all(
                await calendarData_.map(async(data) => {
    
                    const reportId_ = await reportAPI.getReportType(data.report_type_id)
                    data.report = reportId_
    
                    const getStatus_ = getLastStatusApprovement(data.status)
                    data.status = getStatus_
    
                    const format_ = {
                        title       : `${reportId_.data.name}`,
                        id          : data.id,
                        date        : moment(data.date[1], 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD'),
                        start_date  : moment(data.date[0], 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD'),
                        end_date    : moment(data.date[1], 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD'),
                        color       : getRandomColor(),
                        textColor   : 'white'
                    }

                    return format_
                })
            )

            if (Array.isArray(results_) && results_.length > 0) {
                setCalendarData(results_)
            } else {
                setCalendarData([])
            }

        } catch (error) {
            setCalendarData([])
        }  finally {
            setIsCalendarLoading(false)
        }
    }

    const getRevisionData = async(type) => {

        try {
            setIsRevisionLoading(true)

            const params = {
                order : 'desc'
            }

            if (type === 'newest') {
                params.data_type = 'report_revision'
            } else {
                params.data_type = 'report_revision_log'
            }

            const getRevisionData_ = await DashboardAPI.getDashboarData(params)
    
            const results_ = await Promise.all(
                await getRevisionData_[0].results.map(async(data) => {
    
                    const reportData_   = await getDataById(data.id, data.report_type_id)
                    const revisionData  = await data.report_approvement_report.filter((data) => (data.status_approvement === 4)) 
    
                    const reformatData = {
                        report_data         : reportData_[0],
                        revision_data       : revisionData[0],
                    }
    
                    return reformatData
                })
            )

            setRevisionData(results_)
            
        } catch (error) {
            toast.error('Terjadi kesalahan saat mengambil data widget.')
        } finally {
            setIsRevisionLoading(false)
        }        
    }

    const getDetailDashboard = async(data) => {

        try {
            setListReport(null)
            setListReportLoading(true)

            const params = {
                page            : data?.page ? data?.page : 1,
                order           : data?.order ? data?.order : null,
                data_kind       : 'detail',
                data_type       : 'report_by_status_count',
                start_date      : data?.startDate ? data?.startDate : null,
                end_date        : data?.endDate ? data?.endDate : null,
                report_type     : `[${data?.report_type}]`,
                report_status   : data?.type ? data?.type : 1,
                value           : data?.value ? data?.value : null,
                field_id        : data?.field_id ? data?.field_id : null,

            }

            const getDetailDashboard_ = await DashboardAPI.getDashboarData(params)
    
            const results_ = await Promise.all(
                await getDetailDashboard_[0].results.map(async(data) => {

                    setListReportPaginate({
                        page        : getDetailDashboard_[0]?.page,
                        next        : getDetailDashboard_[0]?.next,
                        count       : getDetailDashboard_[0]?.count,
                        previous    : getDetailDashboard_[0]?.previous,
                        total_page  : getDetailDashboard_[0]?.total_page,

                    })
                    const reportData_   = await getDataById(data.id, data.report_type_id)
                    const revisionData  = await data.report_approvement_report.filter((data) => (data.status_approvement === 4)) 
    
                    const reformatData = {
                        report_data         : reportData_[0],
                        report_type         :  data?.report_type
                    }
    
                    return reformatData
                })
            )

            setListReport(results_)
            
        } catch (error) {
            toast.error('Terjadi kesalahan saat mengambil data widget.')
        } finally {
            setListReportLoading(false)
        }        
    }

    const getReportDaily = async(data) => {

        try {
            setListReportDaily(null)
            setListReportDailyLoading(true)
            
            const params = {
                page : data?.page,
                is_draft : false,
                newest_report : true,
                report_type_ids : '24,25,26',
                start_created_at : moment().format('YYYY-MM-DD'),
                end_created_at : moment().format('YYYY-MM-DD')
            }
      
            const getReportDaily_ = await DashboardAPI.getReportDaily(params)
      
            const results_ = await Promise.all(
                await getReportDaily_.results.map(async(data) => {
                    // console.log(data)
                    
                    const reportData_       = await getDataById(data.id, data.report_type_id)
                    const lastTrackStatus_  = await getLastStatusApprovement(data?.report_approvement_report)

                    const reformatData = {
                        report_data         : reportData_[0],
                        report_type         :  data?.report_type,
                        last_track_status   : lastTrackStatus_,
                        read_check          : data?.read_check
                    }
      
                    return reformatData
                })
            )
            setListReportDailyPaginate(getReportDaily_)
            // console.log(results_)
            setListReportDaily(results_)
            
        } catch (error) {
            toast.error('Terjadi kesalahan saat mengambil data laporan harian.')
            // setListReportDaily(null)
        } finally {
            setListReportDailyLoading(false)
        }        
    }

    const getResultReport = async(data) => {

        try {
            setListResultReport(null)
            setListResultReportLoading(true)
            
            const params = {
                page : data?.page,
                is_draft : false,
                newest_report : true,
                report_type_ids : data?.report ? data?.report : '27,28',
                start_created_at : moment().subtract(7, 'days').format('YYYY-MM-DD'),
                end_created_at : moment().format('YYYY-MM-DD')
            }
      
            const getReportDaily_ = await DashboardAPI.getReportDaily(params)
      
            const results_ = await Promise.all(
                await getReportDaily_.results.map(async(data) => {
                    // console.log('getReportDaily_', getReportDaily_)
                    
                    const reportData_       = await getDataById(data.id, data.report_type_id)
                    const lastTrackStatus_  = await getLastStatusApprovement(data?.report_approvement_report)

                    const reformatData = {
                        report_data         : reportData_[0],
                        report_type         :  data?.report_type,
                        last_track_status    : lastTrackStatus_,
                        read_check            : data?.read_check
                    }

                    setListResultReportPaginate(getReportDaily_)
      
                    return reformatData
                })
            )
      
            setListResultReport(results_)
            
        } catch (error) {
            toast.error('Terjadi kesalahan saat mengambil data laporan harian.')
            // setListResultReport(null)

        } finally {
            setListResultReportLoading(false)
        }        
    }

    const readReport = (id, type, data) => {
        const params = {
            id : id,
            newest_report : true,
        }

        DashboardAPI.getReportDaily(params)
            .then((res) => {
                if (type === 'daily') {
                    getReportDaily({page : listReportDailyPaginate?.page})
                    setUpModalPreview(data)

                } else {
                    getResultReport({report : selectReport, page : listResultReportPaginate?.page})
                    // if (data?.last_track_status?.id !== 3) {
                        setUpModalPreview(data)
                    // }
                }
            //    toast.success('Report Dibaca')
            })
    }

    const getDataCategory = () => {

        const params = {
            page_size : 1000
        }

        FileManagementAPI.getCategory(params)
            .then((res) => {
                setDataCategory(res?.results)
            })
    }

    const getDataCategoryFile = () => {
        const params = {
            page_size : 1000
        }

        FileManagementAPI.getList(params)
            .then((res) => {

                setDataCategoryFile(res?.data?.results)

            })
    }

    return <DashboardContext.Provider
        value = {{
            selectReport, 
            setSelectReport,

            listData,
            calendarData,

            isCalendarLoading, 
            setIsCalendarLoading,
            reportData, 
            setReportdata,

            listReportDailyPaginate,
            listResultReportPaginate,

            reportDataId, 
            setReportdataId,

            listReportDaily,
            listReportDailyLoading,

            listResultReport,
            listResultReportLoading,

            revisionData,
            isRevisionLoading,

            listReport,
            listReportLoading,
            listReportPaginate,

            dataCategory,
            dataCategoryFile,

            readReport,
            getReportDaily,
            getResultReport,
            getDataCategory,
            getCalendarData,
            getRevisionData,
            getDashboardStatus,
            getDetailDashboard,
            getDataCategoryFile,
        }}
    > 
        {children}
    </DashboardContext.Provider>
}

export { DashboardContext, DashboardProvider }