import { 
    Modal, 
    ModalBody, 
    ModalHeader, 
} from "reactstrap"

import { 
    useContext, 
    useEffect, 
    useState 
}                               from "react"

//Context
import { ReportContext }        from "../../../context/reportContext"
import moment                   from "moment"

const ModalAcceptTask = (props) => {
    const {
        getData,
        reportData,
    }                  = props

    const {
        showModalAcceptTask,
        setShowModalAcceptTask,

        dataSelected,
        setDataSelected,
        handleAcceptTask,
    }                  = useContext(ReportContext)
    
    
    const [range, setRange]         = useState(0)
    const [endDate, setEndDate]     = useState("")
    const [diffDate, setDiffDate]   = useState("")
    const [startDate, setStartDate] = useState("")

    const handleStringToLink = (text, count, id) => {
        const string_ = text

        const parts = string_.split(' ')
        if (parts.length > 0) {
            const result = parts[0]

            const subParts = result.split('.')

            if (subParts.length === 3) {
                return (
                    <a 
                        href      = {`/${subParts[0]?.toLowerCase()}${subParts[1]?.toLowerCase()}/${subParts[2]}?report_type_id=${count?.length > 0 ? id : reportData?.reportTypeId}&report_id=${count?.length > 0 ? count[0].report_id : dataSelected?.report_id}&type=todo&action=${count?.length > 0 ? 'preview' : 'create' }`} 
                        target    = "_blank"
                        onClick = {() => {

                            // handleAcceptTask(reportData, null, null, getData, result)

                            setDataSelected(null)
                            setShowModalAcceptTask(false) 
                        }}
                    >
                        {string_}
                    </a> 
                )
            } else {
                return (
                    <a 
                        href      = {`/${subParts[0]?.toLowerCase()}/${subParts[1]}?report_type_id=${count?.length > 0 ? id : reportData?.reportTypeId}&report_id=${count?.length > 0 ? count[0] : dataSelected?.report_id}&type=todo&action=${count > 0 ? 'preview' : 'create' }`} 
                        target    = "_blank"
                        onClick = {() => {

                            // handleAcceptTask(reportData, null, null, getData, result)

                            setDataSelected(null)
                            setShowModalAcceptTask(false) 
                        }}
                    >
                        {string_}
                    </a>
                )
            }
        }

        return string_
    }

    const handleStringToLinkRequirement = (text, reportId) => {
        const string_ = text

        const parts = string_.split(' ')
        if (parts.length > 0) {
            const result = parts[0]

            const subParts = result.split('.')

            if (subParts.length === 3) {

                if (reportId) {
                    return (
                        <a 
                            href    = {`/${subParts[0]?.toLowerCase()}${subParts[1]?.toLowerCase()}/${subParts[2]}?&report_id=${reportId}&type=requirement&action=preview`}
                            target  = "_blank"
                        >
                            {string_}
                        </a>
                    )
                } else {
                    return (
                        <a 
                            href    = {`/${subParts[0]?.toLowerCase()}${subParts[1]?.toLowerCase()}/${subParts[2]}`}
                            target  = "_blank"
                        >
                            {string_}
                        </a>
                    )
                }

            } else {

                if (reportId) {
                    return (
                        <a 
                            href    = {`/${subParts[0]?.toLowerCase()}/${subParts[1]}?&report_id=${reportId}&type=requirement&action=preview`} 
                            target  = "_blank"
                        >
                            {string_}
                        </a>
                    )
                } else {
                    return (
                        <a 
                            href    = {`/${subParts[0]?.toLowerCase()}/${subParts[1]}`} 
                            target  = "_blank"
                        >
                            {string_}
                        </a>
                    )
                }

            }
        }

        return string_
    }

    const getTodosByID = (_idReport) => {
        let _data = ""

        const selected = dataSelected?.extra_data?.todos?.data?.filter((dt) => dt?.id === _idReport)
        if (selected?.length > 0) {
            _data = selected[0]
        }

        return _data
    }

    useEffect(() => {
        if (dataSelected) {
            const tempSart      = dataSelected?.track_status?.filter((dt) => dt?.status_approvement === 2)

            if (tempSart?.length > 0) {
                const _Range        = reportData?.reportTypeId === "1" ? 7 : 30
                const _StartDate    = moment(tempSart[0]?.created_at).unix()
                const _EndDate      = (_StartDate) + (_Range * 24 * 60 * 60)

                const _EndDateFormated = moment.unix(_EndDate)
                const _NowFormated     = moment(new Date())
                const _DiffDate         = new Date(_EndDate) > moment(new Date()).unix() ? `${_EndDateFormated.diff(_NowFormated, "days")}  Hari lagi, Segera Selesaikan Tugas` : "Waktu Pengerjaan Telah Melewati Batas"

                setStartDate(_StartDate)
                setEndDate(_EndDate)
                setDiffDate(_DiffDate)
                setRange(_Range)
            }
        }
    }, [dataSelected])

    return (
        <>

            <Modal
                style       = {{maxWidth: '700px', width: 700, height:1000}}
                isOpen      = {showModalAcceptTask}
                centered    = {true}
            >
                <ModalHeader
                    toggle = {() => {
                        setShowModalAcceptTask(false)
                    }}
                >
                    KERJAKAN TUGAS
                </ModalHeader>

                <ModalBody className="p-0 pt-2 pb-3">
                    <div
                        className="text-center mb-2"
                    >
                        <div className="h4 text-uppercase">{reportData?.report} {reportData?.reportTitle}</div>
                        <div className="">
                            {dataSelected?.nomor_surat}
                        </div>
                    </div>
                    <div
                        className   = "bg-light px-5 py-2"
                        style       = {{textAlign: 'justify'}}
                    >
                        <div>
                            Waktu Pengerjaan Tugas {range} Hari
                        </div>
                        <div>
                            Dimulai Sejak <strong>{moment.unix(startDate).format("DD MMMM YYYY")}</strong> hingga <strong>{moment.unix(endDate).format("DD MMMM YYYY")}</strong>
                            <div className="text-danger"><strong>{diffDate}</strong></div>
                        </div>

                        <br />
                        <div className="mb-1"><strong>Lampiran Pendukung</strong></div>
						<div className="">
							{
								dataSelected?.extra_data?.requirements?.data?.map((dt) => {
									return (
										<>
											<div
												className	= "text-primary cursor-pointer"
												onClick		= {() => {

												}}
											>
												<input
													type 		= "checkbox"
													className	= "me-1"
													readOnly 	= {true}
													checked     = {dt?.reports?.length > 0}
												/>
												{
                                                    dt?.reports?.length > 0 ?
                                                        handleStringToLinkRequirement(dt?.name, dt?.reports[0])
                                                    :
                                                        handleStringToLinkRequirement(dt?.name, null)
                                                }
											</div>
										</>
										)
									}
								)
							}
						</div>
                    </div>

                    <div className="px-5 bg-white mt-2">
						<div className="text-center mb-1"><strong>Berkas Yang Harus Dikerjakan</strong></div>
						<div className="">
							{
								dataSelected?.extra_data?.todos?.data?.map((dt) => {
									return (
										<>
											<div
												className	= "text-primary cursor-pointer"
											>
												{
                                                    dt?.id === 7 ? 
                                                        <div className="d-flex">
                                                            <input
                                                                type 		= "checkbox"
                                                                className	= "me-1"
                                                                readOnly 	= {true}
                                                                checked     = {getTodosByID(7)?.reports > 0 || getTodosByID(8)?.reports > 0}
                                                            />
                                                            <div className="d-flex">
                                                                <div className="col-12">
                                                                    {
                                                                        handleStringToLink('IN.7 RenLid', getTodosByID(7)?.reports, getTodosByID(7)?.id)
                                                                    } atau 
                                                                    {
                                                                        handleStringToLink('IN.8 RenPam/Gal', getTodosByID(8)?.reports, getTodosByID(8)?.id)
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    : dt?.id !== 8 ?    
                                                        <>
                                                            <input
                                                                type 		= "checkbox"
                                                                className	= "me-1"
                                                                readOnly 	= {true}
                                                                checked     = {dt?.reports?.length > 0}
                                                            />
                                                            {handleStringToLink(dt?.name, dt?.reports, dt?.id)}
                                                        </>
                                                    : ""
                                                    
                                                }
											</div>
										</>
										)
									}
								)
							}
						</div>

					</div>

                </ModalBody>
                
            </Modal>
        </>
    )
}

export default ModalAcceptTask