// ** React Imports
import { Fragment } from 'react'

// ** Custom Components
import NavbarUser from './NavbarUser'
import NavbarSearch from './Search'
import { Circle, Disc } from 'react-feather'

const ThemeNavbar = props => {
  // ** Props
  const { skin, setSkin, isHidden, setIsHidden } = props
  const Toggler = () => {
    if (!isHidden) {
      return (
        <Disc
          size={20}
          data-tour='toggle-icon'
          className='text-primary toggle-icon'
          onClick={() => setIsHidden(true)}
        />
      )
    } else {
      return (
        <Circle
          size={20}
          data-tour='toggle-icon'
          className='text-primary toggle-icon'
          onClick={() => setIsHidden(false)}
        />
      )
    }
  }

  return (
    <Fragment>
      {/* <Toggler/> */}
      {/* <NavbarSearch/> */}
      <NavbarUser skin={skin} setSkin={setSkin} />
    </Fragment>
  )
}

export default ThemeNavbar
