import { useState, useEffect, useRef } from "react"
import { Modal, ModalBody, ModalHeader, Col, Row, Pagination } from "reactstrap"
import CustomTable from "@components/custom-table"
import headerTable from "./headerTable"
import toast from "react-hot-toast"
import "./index.scss"
import userManagementAPI from "../../../../service/pages/user-management"

const ModalFormEmployee = ({ show, onClose, dataPegawai, data, signer }) => {
	// states
	const [datas, setDatas] 				  = useState([])
	const [loading, setLoading] 		 	  = useState(false)
	const [dataPagination, setDataPagination] = useState({
		current_page: 1,
		data_total: 10,
		has_next: false,
		has_previous: false,
		page_total: 1
	})

	// hook

	// custom table
	const {
		CustomTableHeader,
		CustomTableBody,
		CustomTableSearch,
		CustomTablePaginate,
		CustomTableLoading,
		CustomTableEmptyData,
	} = CustomTable

	const params = useRef({
		page: 1,
		keyword: "",
	})


	const getData = () => {
		setLoading(true)

		const params_ = {
			...params.current,
			scope_work_units : true
		} 

		// if (signer) {
		// 	params_.signer = true
		// }

		userManagementAPI.getUserManagement(params_).then(
			(res) => {
				setLoading(false)

				if (res.results !== null) {

					setDatas(res.results)
					setDataPagination({
						current_page: res.page,
						data_total: res.count,
						has_next: Boolean(res.next),
						has_previous: Boolean(res.previous),
						page_total: res.total_page
					})
				}
			}
		).catch(
			(err) => {
				toast.error("terjadi kesalahan saat mengambil data")
				console.log(err)
			}
		)
	}

	useEffect(() => {
		getData()

	}, [])

	return (
		<Modal
			size			= "lg" 
			isOpen			= {show} 
			toggle			= {() => {
				onClose()
				params.current.keyword = ''
				params.current.page = 1
				getData()
			}} 
			unmountOnClose	= {true}
		>
			<ModalHeader
				toggle	= {() => { 
					onClose() 
					params.current.keyword = ''
					params.current.page = 1
					getData()
				}}
			>
				Pilih Data Pegawai
			</ModalHeader>

			<ModalBody>
				<Row>
					<Col>
						<CustomTableSearch
							title="Cari..."
							onSearch={(value) => {
								params.current.keyword = value
								params.current.page = 1
								getData()
							}}
						/>
					</Col>
				</Row>
				<div className="d-flex justify-content-end m-1">
					<CustomTablePaginate
						next		= {dataPagination?.has_next}
						prev		= {dataPagination?.has_previous}
						total		= {dataPagination?.data_total}
						limit		= {10}
						onClick		= {(value) => {
							if (value === "next") {
								params.current.page = params.current.page + 1
							} else {
								params.current.page = params.current.page - 1
							}
							getData()
						}}
						currentPage	= {dataPagination?.current_page}
					/>
				</div>

				{/* table header */}
				<CustomTableHeader data={headerTable} />

				{/* table body */}
				{!loading ? (
					datas.length > 0 ? (
						datas.map((item, idx) => (
							<CustomTableBody
								key			= {item?.id}
								className	= {`cursor-pointer card-body-employee ${data?.id === item?.id ? "bg-primary text-white" : "bg-light-primary"}`}
								onClick		= {() => {
									dataPegawai(item)
									onClose()
									params.current.keyword = ''
									params.current.page = 1
									getData()
								}}
								id 			= {`signer_${idx}`}
							>
								<Col md="2">
									<span>{item?.name}</span>
								</Col>
								<Col md="2">
									<span>{item?.nip}</span>
								</Col>
								<Col md="3">
									<span>{item?.work_unit?.work_unit_level?.name} {item?.work_unit?.name}</span>
								</Col>
								<Col md="2">
									<span>{item?.grade?.name}</span>
								</Col>
								<Col md="2">
									<span>{item?.position?.name}</span>
								</Col>
							</CustomTableBody>
						))
					) : (
						<CustomTableEmptyData />
					)
				) : (
					<CustomTableLoading />
				)}
			</ModalBody>
		</Modal>
	)
}

export default ModalFormEmployee
