import React, { useContext, useEffect, useState }   from 'react'
import { ArrowRight, ArrowLeft }                    from 'react-feather'
import { Button, Input, Modal, ModalHeader }        from 'reactstrap'
import { ReportContext }                            from '../../../context/reportContext'
import { getReportField }                           from '../../../utility/report-type-id'
import Select                                       from 'react-select'
import statusPenyelesaianOptionLin4                 from '../../../@core/components/input-dasar-surat/statusPenyelesaianOptionLin4'
import statusPenyelesaianOptionLin5                 from '../../../@core/components/input-dasar-surat/statusPenyelesaianOptionLin5'
import toast                                        from 'react-hot-toast'
import moment                                       from 'moment'

const ModalStatusAndInstruction = (props) => {

    const {
        show,
        data,
        onClose,
        handlePDF,
        reportData,
        setDataSelected,
        headerReport,
    }                                       = props

    const {
        data : listData,
		countCopies,
        handleUpdateCountCopies,
        reportTypeField,
        updateReport,
        handleAcceptTask,
        getData,
        setShowModalStatus
	}				                        = useContext(ReportContext)

    const [dataUrl, setDataUrl]             = useState("")
    const [fetchStatus, setFetchStatus]     = useState(false)
    const [showDetail, setShowDetail]       = useState(false)
    const [status, setStatus]               = useState(false)
    const [keterangan, setKeterangan]		= useState("")

    const handlePdfType = async() => {
		//update count
		await handleUpdateCountCopies(data?.report_id, "pdf")

        await handlePDF(data, setDataUrl, countCopies?.pdf)
	}

    const actionUpdate = (reportId, formData) => {

		const newFieldData_ = getReportField(formData, reportTypeField)

		const finalFormData = {
			report_type_id 		: parseInt(reportData?.reportTypeId),
			report_values 		: newFieldData_,
			report_attachments 	: []
		}

		if (formData?.submit_type === "draft") {
            finalFormData.is_draft = true
        } else {
            finalFormData.is_draft = false
        }

        const otherAction = () => {
            const handleToastSuccess = () => {
                toast.success("Berhasil Selesaikan Surat")
                getData(reportData?.reportTypeId, headerReport)
                setShowModalStatus(false)
            }

            handleAcceptTask(reportData, data?.report_id, 7, handleToastSuccess)
        }
        
		updateReport(reportId, finalFormData, otherAction)
	}

    const handleSubmit = () => {

        if (!status) {
            toast.error("Pilih Status Terlebih Dahulu")
        } else if (keterangan === "") {
            toast.error("Isi Petunjuk Pimpinan Terlebih Dahulu")
        } else {
            const formData = data
            formData.petunjuk_pimpinan 			= keterangan
            formData.tanggal_petunjuk_pimpinan 	= moment(new Date()).unix()
            formData.status_penyelesaian        = status?.value

            if (formData.penandatangan) {
                formData.penandatangan = formData.penandatangan.user_id
            }
    
            if (formData.pelaksana_operasi.length > 0) {
                formData.pelaksana_operasi = formData.pelaksana_operasi.map((data) => (data.user_id))
            }

            if (formData.sumber_surat) {
                if (Array.isArray(formData?.sumber_surat) && formData?.sumber_surat?.length > 0) {
                    formData.sumber_surat = formData.sumber_surat.map((data) => data?.report_id)
                } else if (typeof formData.sumber_surat === 'object') {
                    if ("id" in formData.sumber_surat) {
                        formData.sumber_surat = formData?.sumber_surat?.id
                    } else {
                        formData.sumber_surat = formData?.sumber_surat?.report_id
                    }
                }
            }

            if (Array.isArray(formData?.sumber_kartutik) && formData?.sumber_kartutik?.length > 0) {
                formData.sumber_kartutik = formData.sumber_kartutik.map((data) => data?.report_id)
            } else {
                delete formData.sumber_kartutik
            }

            actionUpdate(data?.report_id, formData)
        }
	}

    useEffect(() => {
        if (fetchStatus) {
            handlePdfType()
        }

        if (data) {
            if (data?.petunjuk_pimpinan && data?.petunjuk_pimpinan !== "__blank") {
                setKeterangan(data?.petunjuk_pimpinan)
            } else {
                setKeterangan("")
            }

            if (
                statusPenyelesaianOptionLin4.find((dt) => data?.status_penyelesaian?.includes(dt.value)) ||
                statusPenyelesaianOptionLin5.find((dt) => data?.status_penyelesaian?.includes(dt.value))
            ) {
                if (reportData?.reportTypeId === "27") {
                    console.log("data?.status_penyelesaian", data?.status_penyelesaian)
                    setStatus({
                        value : statusPenyelesaianOptionLin4.find((dt) => data?.status_penyelesaian?.includes(dt.value))?.value,
                        label : statusPenyelesaianOptionLin4.find((dt) => data?.status_penyelesaian?.includes(dt.value))?.label,
                    })
                } else {
                    setStatus({
                        value : statusPenyelesaianOptionLin5.find((dt) => data?.status_penyelesaian?.includes(dt.value))?.value,
                        label : statusPenyelesaianOptionLin5.find((dt) => data?.status_penyelesaian?.includes(dt.value))?.label,
                    })
                }
            }
        } else {
            setKeterangan("")
        }

    }, [data])

  return (
    <div>
        <Modal
            style       = {{maxWidth: '1320px', width: 1320, height:800}}
            isOpen      = {show}
            // size        = "xl"
            onOpened    = {() => handlePdfType()}
            onClosed    = {() => { 
                setDataUrl("") 
            }}
        >
            <ModalHeader
                toggle={() => {
                    onClose()
                    setFetchStatus(false)
                }}
            >
                Preview
            </ModalHeader>

            <div className='p-0 m-0'>
                
                <div className='d-flex justify-content-center1'>
                    <div className='col-8 bg-dark'>
                        <embed type='application/pdf' height={800} width={880} src={`${dataUrl}`}></embed>
                    </div>
                    <div className='col-4 bg-white p-2'>
                        <div
                            className='mb-2 h5'
                        >
                            Detail
                        </div>
                        <div>
                            <div
                                className='mb-1'
                            >
                                <div
                                    className='h6 m-0 p-0'
                                >
                                    Nomor Surat
                                </div>
                                {data?.nomor_surat}
                            </div>
                            <div
                                className='mb-1'
                            >
                                <div
                                    className='h6 m-0 p-0'
                                >
                                    Dibuat Pada
                                </div>
                                {data?.created_at}
                            </div>
                            <div
                                className='mb-2'
                            >
                                <div
                                    className='h6 m-0 p-0'
                                >
                                    Dibuat Oleh
                                </div>
                                {data?.created_by?.name}
                            </div>
                            <div
                                className='mb-2'
                            >
                                <div
                                    className='h6 m-0 p-0'
                                >
                                    Status Penyelesaian
                                </div>
                                <Select
                                    onChange            = {(e) => {
                                        setStatus(e)
                                    }}
                                    className           = {`react-select`}
                                    classNamePrefix     = "select"
                                    placeholder         = "Pilih Status Penyelesaian"
                                    options             = {reportData?.reportTypeId === "27" ? statusPenyelesaianOptionLin4 : statusPenyelesaianOptionLin5}
                                    value 				= {status}
                                />
                            </div>

                            <div
                                className='mb-2'
                            >
                                <div
                                    className   = 'h6 m-0 p-0'
                                >
                                    Petunjuk Pimpinan
                                </div>

                                <Input
                                    type		= "textarea"
                                    placeholder	= "Beri Petunjuk Pimpinan.."
                                    value 		= {keterangan}
                                    style       = {{
                                        marginTop : '7px',
                                        minHeight : '200px'
                                    }}
                                    onChange	= {(e) => {
                                        setKeterangan(e.target.value)
                                    }}
                                />
                               
                            </div>

                            <div>
                                <Button
                                    className   = 'col-12'
                                    color       = 'primary'
                                    onClick     = {() => {
                                        handleSubmit()
                                    }}
                                    style       = {{
                                        marginBottom : '5px'
                                    }}
                                >
                                    {
                                        status ? "Selesai" : "Tanda Tangan"
                                    }
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    </div>
  )
}

export default ModalStatusAndInstruction