import moment                   from "moment"

import { 
    useEffect, 
    useState 
}                               from "react"

import { 
    Edit,
}                               from "react-feather"

import { 
    Card, 
    Button,
    CardBody,
    Table, 
}                               from "reactstrap"

import toast                    from "react-hot-toast"
import ProfileUpdate            from "./profile_update"
import serviceAPI               from "../../../service/drive/service"
import userManagementAPI        from "../../../service/pages/user-management"
import reportAPI                from "../../../service/report"
import baseURL                  from "../../../service"
import UserManagementUpdate     from "../../configuration/user-management/component/UserManagementUpdate"

import 'moment/locale/id'
import { getUserData } from "../../../utility/Utils"

const ProfileBiodata = (props) => {
    
    const {
        userBiodata,
        isProfile,
    } = props

    const url = baseURL.replace("/api", "")

    const [isUpdateModalVisible, setIsUpdateModalVisible] = useState(false)
    const [userData, setUserData]                         = useState(userBiodata)                         
    const [bidang, setBidang]                             = useState(null)

    const [biodata, setBiodata]     = useState([
        {
            label   : "Email",
            value   : ""
        },
        {
            label   : "No. HP",
            value   : ""
        },
        {
            label   : "Pangkat",
            value   : ""
        },
        {
            label   : "Golongan",
            value   : ""
        },
        {
            label   : "Satuan Kerja",
            value   : ""
        },
        {
            label   : "Direktorat/ Bidang",
            value   : ""
        },
        {
            label   : "Alamat",
            value   : ""
        },
    ])

    const handleSetBiodata = (_data) => {
        setBiodata([
            {
                label   : "Email",
                value   : `${_data?.email}`
            },
            {
                label   : "No. HP",
                value   : `${_data?.phone_number}`
            },
            {
                label   : "Pangkat",
                value   : `${_data?.grade?.name}`
            },
            {
                label   : "Golongan",
                value   : `${_data?.classes?.name}`
            },
            {
                label   : "Satuan Kerja",
                value   : `${_data?.work_unit?.work_unit_level?.name?.toLowerCase() !== "kejaksaan agung" ? userData?.work_unit?.work_unit_level?.name : ""} ${userData?.work_unit?.name}`
            },
            {
                label   : "Direktorat/ Bidang",
                value   : `${bidang ? bidang?.name : "-"}`
            },
            {
                label   : "Alamat",
                value   : `${_data?.address}`
            },
        ])
    }

    const getBidangData = (id) => {
        const params = {
            id      : id,
            source  : 'bidang'
        }

        reportAPI.getAdditionalData(params)
            .then((res) => {
                if (res?.results?.length > 0) {
                    setBidang(res?.results[0])
                }
            })
    }

    const handleUpdate = async (data, profilePicture) => {
        const payload = {
            user          : userData?.user_id,
            name          : data?.name !== undefined ? data?.name : "",
            email         : data?.email !== undefined ? data?.email : "",
            address       : data?.address !== undefined ? data?.address : "",
            phone_number  : data?.phone_number !== undefined ? data?.phone_number : "",
            photo         : "",
            grade_id      : data?.grade !== undefined ? data?.grade.value : 0,
            role_id       : data?.role !== undefined ? data?.role.value : 0,
            position_id   : data?.position !== undefined ? data?.position.value : 0,
            work_unit_id  : data?.workunit !== undefined ? data?.workunit.value : 0,
            nip           : data?.nip !== undefined ? data?.nip : "",
            classes_id    : data?.class !== undefined ? data?.class.value : 0,
            sector_id     : data?.sector !== undefined ? data?.sector.value : 0,
            code_id          : data?.bidang !== undefined ? data?.bidang : 0,
            code_sector_id   : data?.sektor !== undefined ? data?.sektor : 0,
            code_subdir_id   : data?.subdirektorat !== undefined ? data?.subdirektorat : 0,
            code_seksi_id    : data?.seksi !== undefined ? data?.seksi : 0,
        }

        if (profilePicture) {
            const dataPhoto = new FormData()
            dataPhoto.append("service", "user-management")
            dataPhoto.append("file", profilePicture)
      
            const resFoto = await serviceAPI.uploadFileService(dataPhoto)
            
            payload.photo = resFoto.data.url_file
        } else {
            payload.photo = getUserData().photo
        }

        userManagementAPI.updateUserManagement(payload, userData?.id)
            .then((res) => {
                toast.success("Data Pengguna Berhasil Diubah")
                setIsUpdateModalVisible(false)

                if (isProfile) {
    				localStorage.setItem('userData', JSON.stringify(res))
                    window.location.reload()
                }

                setUserData(res)
                
                getBidangData(res?.code)

                handleSetBiodata(res)
            })
    }

    const fallbackImage_ = (val) => {
        val.target.src = `https://ui-avatars.com/api/?name=${`${userData?.name}`}&background=4e73df&color=fff&bold=true`
    }

    useEffect(() => {
        setUserData(userBiodata)
    }, [userBiodata])
    
    useEffect(() => {
        getBidangData(userData?.code_id)
        handleSetBiodata(userData)
    }, [userData])

    useEffect(() => {
        handleSetBiodata(userData)
    }, [bidang])

    return (
        <>
            <Card
                className="col-12 h-100 bg-white rounded m-0"
            >
                <CardBody
                    className   = "d-lg-flex d-block p-0"
                >
                    <div
                        className       = "col-lg-4 col-12 p-1 bg-light-primary text-white rounded-lg-start1"
                    >
                        <ul>
                            <li><strong>Informasi Personal</strong></li>
                        </ul>
                        <div
                            className   = "d-flex px-1"
                        >
                            <div className="col-lg-4 col-2 mb-lg-0 me-1">
                                <img
                                    src         = {(userData?.photo && userData?.photo !== "") ? `${url}${userData?.photo}` : `https://ui-avatars.com/api/?name=${`${userData?.name}`}&background=4e73df&color=fff&bold=true`}
                                    className   = "rounded col-lg-12 col-10"
                                    onError     = {fallbackImage_}
                                />
                            </div>

                            <div className="col-7 mb-lg-0">
                                <div
                                    className   = "h5 text-dark m-0 p-0"
                                >
                                    {userData?.name}
                                </div>

                                <div>
                                    <div
                                        className   = "text-sm text-dark"
                                        style={{
                                            marginTop       : '7px',
                                            marginBottom    : '7px'
                                        }}
                                    >
                                        <small>Privilage Role</small>
                                        <div className="h5">{userData?.role_detail?.name}</div>
                                    </div>
                                    <div
                                        className   = "text-sm text-dark"
                                        style={{
                                            marginTop       : '7px',
                                            marginBottom    : '7px'
                                        }}
                                    >
                                        <small>Akun Dibuat</small>
                                        <div className="h5">{moment(userData?.created_at).format("DD MMMM YYYY")}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div
                        className       = "col-lg-8 col-12 p-1 d-lg-flex d-block justify-content-between"
                    >
                        <div className="col-lg-11 col-12 h-100">
                            <Table className="table-borderless">
                                <tbody>
                                    {
                                        biodata.map((dt) => {
                                            return (
                                                <tr>
                                                    <td className="col-lg-4 col-6 py-0">
                                                        {dt.label}
                                                    </td>
                                                    <td className="p-0">
                                                        :
                                                    </td>
                                                    <td className="col-lg-8 col-6 py-0">
                                                        {dt.value}
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                    
                                </tbody>
                            </Table>
                        </div>

                        <div className="col-lg-1 col-12 text-end mt-lg-0 mt-1">
                            <Button
                                size            = "sm"
                                color           = "light"
                                className       = "btn-icon col-12"
                                onClick         = {() => {
                                    setIsUpdateModalVisible(true)
                                }}
                            >
                                <Edit
                                    size        = "20"
                                />
                            </Button>
                        </div>
                    </div>
                </CardBody>
            </Card>

            {
                isProfile ? (
                    <ProfileUpdate
                        data            = {userData ? userData : null}
                        size            = "lg"
                        show            = {isUpdateModalVisible}
                        title           = "Ubah Profil"
                        setShow         = {setIsUpdateModalVisible}
                        handleUpdate    = {handleUpdate}
                    />
                ) : (
                    <UserManagementUpdate
                        data            = {userData ? userData : null}
                        size            = "lg"
                        show            = {isUpdateModalVisible}
                        title           = "Ubah Pengguna"
                        setShow         = {setIsUpdateModalVisible}
                        handleUpdate    = {handleUpdate}
                    />
                )
            }
            
        </>
    )
}

export default ProfileBiodata