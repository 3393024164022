import {
	Button,
	ButtonGroup,
	Card,
	CardBody,
	Col,
	Input,
	InputGroup,
	Label,
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
	Row,
} 										from "reactstrap"


import { Fragment, useEffect, useState } 	from "react"
import { RefreshCcw, Search } 			from "react-feather"

import moment	 						from "moment"
import PerfectScrollBar 				from "react-perfect-scrollbar"
import CustomTable 						from "../../../@core/components/custom-table"
import reportAPI 						from "../../../service/report"
import "./index.scss"

import { selectThemeColors } 			from "@utils"
import Select 							from "react-select"
import { getFieldName } 				from "../../../utility/report-field-id"
import userManagementAPI 				from "../../../service/pages/user-management"
import InputMultipleSumber 				from "../input-multiple-sumber"
import { useLocation, useNavigate }     from 'react-router-dom'

const InputSumberText = (props) => {
	

	const { onClick, value, invalid, placeholder, isKartutik, id } = props

	return (
		<InputGroup>
			<Input
				placeholder		= {placeholder ? placeholder : "Pilih Sumber"}
				readOnly
				invalid			= {invalid}
				value			= {
					value ? 
						value
					: 
						""
				}
			/>

			<Button.Ripple
				style			= {{ zIndex: 0 }}
				color			= "primary"
				onClick			= {onClick}
				id 				= {`selectSource${id ? `_${id}` : ""}`}
			>
				{!value ? (
					<Fragment>
						<Search size={15} className="me-1" />
						Cari
					</Fragment>
				) : (
					<Fragment>
						<RefreshCcw size={15} className="me-1" />
						Ubah
					</Fragment>
				)}
			</Button.Ripple>
		</InputGroup>
	)
}

const InputSumber = (props) => {

	const { 
		type,
		value, 
		invalid, 
		setValue,
		onChange,
		namaField,
		id,
		hideOption,
		placeholder,
		reportTypeId,
		defaultSource,
		title,
		isProdukIntelijen,
		field_name,
		isKartutik,
		setSelectedData,
		_setSelectedSource
	} = props


	const params = useLocation()
	const navigate = useNavigate()

	// states

	const [dataPagination, setDataPagination] 			= useState(null)
	const [loading, setLoading] 						= useState(false)
	const [showModal, setShowModal] 					= useState(false)
	const [reportType, setReportType]					= useState(null)
	const [sourceValue, setSourceValue]					= useState([])
	const [sourceOptions, setSourceOption]				= useState(null)
	const [loadingOptions, setLoadingOptions]			= useState(true)
	const [selectedSource, setSelectedSource]			= useState(null)
	const [selectedSourceValue, setSelectedSourceValue] = useState([])

	// Custom Table
	const {
		CustomTableBody,
		CustomTableSearch,
		CustomTablePaginate,
		CustomTableLoading,
		CustomTableEmptyData,
	} = CustomTable
	
	// get data
	const getData = (page) => {
		setLoadingOptions(true)
		
		reportAPI.getReportType(reportTypeId)
        .then((res) => {
				let filter_ = null

				const data_ 								= res.data.field_report_type

				const bySumberSurat 						= data_.find((data) => (data.name === 'sumber-surat'))
				const bySumberKartutik 						= data_.find((data) => (data.name === 'sumber-kartutik'))
				const byKartutikTersangka					= data_.find((data) => (data.name === 'kartutik-tersangka'))
				const byKartutikBarangCetakan 				= data_.find((data) => (data.name === 'kartutik-barang-cetakan'))
				const byKartutikMediaKomunikasi 			= data_.find((data) => (data.name === 'kartutik-media-komunikasi'))
				const byKartutikOrganisasiKemasyarakatan 	= data_.find((data) => (data.name === 'kartutik-organisasi-kemasyarakatan'))
				const byKartutikBiodata 					= data_.find((data) => (data.name === 'identitas-lengkap'))
				const bySpOpsin 							= data_.find((data) => (data.name === 'sp-operasi-intelijen'))

				if (namaField) {
					filter_ = data_.find((data) => (data.name === namaField))
				} else {
					if (field_name === "sp-operasi-intelijen") {
						filter_ = bySpOpsin
					} else if (bySumberSurat && type !== "multiple") {
						filter_ = bySumberSurat
					} else if (byKartutikBarangCetakan) {
						filter_ = byKartutikBarangCetakan
					} else if (byKartutikTersangka) {
						filter_ = byKartutikTersangka
					} else if (byKartutikMediaKomunikasi) {
						filter_ = byKartutikMediaKomunikasi
					} else if (byKartutikOrganisasiKemasyarakatan) {
						filter_ = byKartutikOrganisasiKemasyarakatan
					} else if (byKartutikBiodata) {
						filter_ = byKartutikBiodata
					} else if (bySumberKartutik && type === "multiple") {
						filter_ = bySumberKartutik
					} else if (byDinasPermintaanKeterangan) {
						filter_ = byDinasPermintaanKeterangan
					}
				}

                return filter_?.source

            }).then((results) => {
				const sourceList_ = []

				if (results !== null) {
					reportType.map((report) => (
						results.map((result) => {
							if (report.id === result.field_source_report_type.report_type_source) {
								const link = `${report?.type.replace(".", "")}/${report?.name.replace(`${report?.type}.`, "").replace(` `, "_")}`

								sourceList_.push({
									id		 : result?.field_source_report_type?.report_type_source,
									value 	 : report?.id,
									label    : report?.description,
									type	 : report?.type,
									link   	 : link,
									name 	 : report?.name,
									source	 : result?.field_source_report_type?.condition_or_link
								})
							}
						})
					))

					if (sourceList_?.length === 1) {
						setSelectedSource(sourceList_[0])
					}
				}

				setSourceOption(sourceList_)
				setLoadingOptions(false)
		}).catch(() => { setLoadingOptions(false) })
	}

	const getReportType = () => { 
		const params = {
			page_size : 100
		}

		reportAPI.getReportTypeAll(params).then(
			res => {
				setReportType(res?.results)
			}
		).catch(() => {})
	}

	const getSelectedSource = async(selectedSource, page) => {

		setLoading(true)
		// const url = `?report_type_id=${selectedSource.id}`

		let url = ""
		if (selectedSource?.source) {
			url = selectedSource?.source?.replace("report_type__id", "report_type_id")
		} else {
			url = `?report_type_id=${selectedSource.id}`
		}

		const getReportType 		 = await reportAPI.getReportType(selectedSource.id)
		const getReportConditionLink = await reportAPI.getReportConditionLink(`${url}${page ? `&page=${page}` : ""}`)

		const newFormatData_ = []

		if (getReportConditionLink?.results !== null) {

			await Promise.all(
				await getReportConditionLink.results?.map(async (result, index) => {

					let data_               = {}
					data_                   = await getFieldName(result, getReportType?.data?.field_report_type)
					data_.index             = ((((getReportConditionLink?.page - 1) * getReportConditionLink?.page_size)) + (index + 1))

					const getUserData_  	= await userManagementAPI.getUserManagement({user: parseInt(result.created_by)})
					data_.created_by    	= getUserData_.results[0]
					data_.satuan_kerja  	= `${getUserData_?.results[0]?.work_unit?.work_unit_level?.name} ${getUserData_?.results[0]?.work_unit?.name}`

					newFormatData_.push(data_)
				})
			)

			setDataPagination({
				total		: getReportConditionLink?.count,
				limit   	: getReportConditionLink?.page_size,
				currentPage : getReportConditionLink?.page,
				next 		: getReportConditionLink?.next,
				prev		: getReportConditionLink?.previous
			})

			console.log('newFormatData', newFormatData_)
			setSourceValue(newFormatData_.sort((a, b) => a.index - b.index))
		}
		setLoading(false)
	}

	useEffect(() => {
		if (reportType) {
			getData()
		} else {
			getReportType()
		}
	}, [reportType])

	useEffect(() => {
		if (selectedSource) {
			getSelectedSource(selectedSource)

			if (_setSelectedSource) {
 				_setSelectedSource(selectedSource)
			}
		}
	}, [selectedSource])

	useEffect(() => {
		if (defaultSource) {
			setSelectedSource(defaultSource)
		}
	}, [defaultSource])

	useEffect(() => {
		if (selectedSourceValue?.length !== 0) {
			onChange(selectedSourceValue)
		}
	}, [selectedSourceValue])

	useEffect (() => {
		if (value) {
			setSelectedSource(value?.report_type_id)
			setSelectedSourceValue(value)
		}
	}, [])

	useEffect(() => {
		if (type === "multiple") {
			if (value) {
				onChange(value)
			}
		}
	}, [value])

	return (
		<Fragment>
			
			{
				// type multiple u/ sumber kartutik
				type === "multiple" ?
					<InputMultipleSumber
						value		= {value}
						invalid		= {invalid}
						onClick		= {() => { setShowModal(!showModal) }}
						placeholder	= {placeholder}
						setValue 	= {setValue}
					/>
				:
					<InputSumberText
						value		= {
							typeof (selectedSourceValue) === "object" ? 
								(selectedSourceValue && "judul" in selectedSourceValue) ? 
									selectedSourceValue?.judul !== '-' ? selectedSourceValue?.judul : selectedSourceValue?.sumber_bapul 
								: 
								(selectedSourceValue && "nama_lengkap" in selectedSourceValue) ? 
									selectedSourceValue?.nama_lengkap 
								:
								(selectedSourceValue && "nama_organisasi" in selectedSourceValue) ? 
									selectedSourceValue?.nama_organisasi 
								:
								(selectedSourceValue && "nama_pekerjaan" in selectedSourceValue) ? 
									selectedSourceValue?.nama_pekerjaan 
								:
								(selectedSourceValue && "nomor_surat" in selectedSourceValue) ? 
									selectedSourceValue?.nomor_surat 
								: 
								(selectedSourceValue && "nomor" in selectedSourceValue) ? 
									selectedSourceValue?.nomor 
								: 
								(selectedSourceValue && "catatan" in selectedSourceValue) ? 
									selectedSourceValue?.catatan 
								: (selectedSourceValue && "nama_pelapor" in selectedSourceValue) ? 
								selectedSourceValue?.nama_pelapor 
								: ""
							: ""
						}
						invalid		= {invalid}
						onClick		= {() => { setShowModal(!showModal) }}
						onChange	= {onChange}
						placeholder	= {placeholder}
						id 			= {id}
					/>
			}

			{/* modal */}
			<Modal
				style		= {{maxWidth: "70%"}}
				size		= "lg"
				isOpen		= {showModal}
				toggle		= {() => setShowModal(!showModal)}
				onOpened	={() => {
					if (params.search && type !== "multiple") {
						if (value) {
							const defaultOptions = sourceOptions?.filter((val) => val.id === Number(value?.report_type_id))
							setSelectedSource(defaultOptions)
							setSelectedSourceValue(value)
							getSelectedSource(value?.report_type_id)
							navigate(params.pathname)
						}
					}
				}}
				
			>
				<ModalHeader toggle={() => setShowModal(!showModal)}>
					Sumber Surat
				</ModalHeader>

				<ModalBody>
					<Row className="mb-2">
						<Col className="d-flex align-items-center">
						{
							hideOption === true ? (
								null
							) : (
								<>
									<p className="fw-bolder m-0 pe-1">Pilih Sumber Surat : </p>
									<Select
										theme			= {selectThemeColors}
										style			= {{zIndex: 999}}	
										value			= {selectedSource}
										options			= {sourceOptions}
										onChange		= {(selected) => { setSelectedSource(selected) }}
										className		= {'react-select w-50'}
										isDisabled		= {loadingOptions || isProdukIntelijen}
										placeholder		= "Pilih Sumber Surat"
										id 				= "sourceOption"
										classNamePrefix	 = "select"
									/>

									<div className		= "px-1">
										{
											(selectedSource && !isKartutik) &&
											<Button
												color		= "primary"
												onClick		= {() => {
													getSelectedSource(selectedSource)
												}}
											>
												<RefreshCcw
													className	= "me-1"
													size		= {16}
												/>
												Muat Ulang Data
											</Button>
										}
									</div>
								</>
							)
						}
						</Col>


						{/* <Col md={4}>
							<CustomTableSearch
								title		= "Cari..."
								onSearch	= {(e) => {
									// params.current.keyword = e
									// params.current.page = 1
									// getData(actived?.report_type_id)
								}}
							/>
						</Col> */}
					</Row>

					<div className="d-flex justify-content-end mt-1">
						<CustomTablePaginate
							total			= {dataPagination?.total}
							limit			= {dataPagination?.limit}
							currentPage		= {dataPagination?.currentPage}
							next			= {dataPagination?.next}
							prev			= {dataPagination?.prev}
							onClick			= {(value) => {
								console.log("CustomTablePaginate", value)
								if (value === "next") {
									getSelectedSource(selectedSource, dataPagination?.currentPage + 1)
								} else {
									getSelectedSource(selectedSource, dataPagination?.currentPage - 1)
								}
								
							}}
						/>
					</div>

					<div>
						{
							isKartutik !== true &&
								<div
									className	= "row row-cols-6 mb-1 px-1"
								>
									{
										sourceOptions?.map((dt) => {
											return (
												<a 
													className= "btn btn-primary me-1 mb-1 d-flex align-items-center justify-content-center"
													href    = {`/${dt?.link}?action=create`}
													target  = "_blank"
												>
													Tambah {dt?.name}
												</a>
											)
										})
									}
								</div>
						}
					</div>

					{
						sourceValue.length > 0 ?
							<>
								{/* table Header */}
								<div className={`wrap-header `}>
									<Row>
										<Col md={4}>
											<h6>
												{
													"judul" in sourceValue[0] ?
														"Judul"
													:
													"nama_lengkap" in sourceValue[0] ?
														"Nama Lengkap"
													:
													"nama_organisasi" in sourceValue[0] ?
														"Nama Organisasi"
													:
													"nomor_surat" in sourceValue[0] ?
														"Nomor Surat" 
													:
													"catatan" in sourceValue[0] ?
														"Catatan"
													:
													"nama_pelapor" in sourceValue[0] ?
														"Nama Pelapor"
													:
														"Nomor"
												}
											</h6>
										</Col>
										<Col md={4}>
											<h6>
												Satuan Kerja
											</h6>
										</Col>
										<Col md={4}>
											<h6>Tanggal Pembuatan Surat</h6>
										</Col>
									</Row>
								</div>

								<PerfectScrollBar style={{ maxHeight: "420px" }}>

									{/* check is selected is empty */}
									{
										selectedSource ? 
											loading ? 
												<CustomTableLoading/>
											:
													sourceValue ?
													sourceValue.map((item, index) => (
														<CustomTableBody
															key			= {`source_value_${index}`}
															id			= {`source_value_${index}`}
															className	= "bg-light-primary cursor-pointer card-body-sumber"
															onClick		= 	{() => {
																setShowModal(!showModal)
																setSelectedSourceValue(item)
																if (setSelectedData) {
																	setSelectedData(item)
																}

																if ("report_id" in item) {
																	onChange(item?.report_id)
																} else if ("id" in item) {
																	onChange(item?.id)
																} else {
																	onChange(item)
																}
																if (type === "multiple") {
																	if (Array.isArray(value)) {
																		setValue([...value, item])
																	} else {
																		setValue([item])
																	}

																}
															}}
														>
															<Col md={4}>
																{
																	"judul" in item ?
																		item?.judul !== '-' ? item?.judul :  item?.sumber_bapul
																	:
																	"nama_lengkap" in item ?
																		item?.nama_lengkap
																	:
																	"nama_organisasi" in item ?
																		item?.nama_organisasi
																	:
																	"nomor_surat" in item ?
																		item?.nomor_surat
																	:
																	"catatan" in item ?
																		item?.catatan
																	:
																	"nama_pelapor" in item ?
																		item?.nama_pelapor
																	:
																		item?.nomor
																} 
															</Col>
															<Col md={4}>
																{item?.satuan_kerja}
															</Col>
															<Col md={4}>
																{
																	moment(item?.created_at, "DD MMMM YYYY").format('DD MMMM YYYY')
																}
															</Col>
														</CustomTableBody>
													))
												:
													<CustomTableEmptyData/>
										:	
											<CustomTableEmptyData/>
									}

								</PerfectScrollBar>
							</>
						:
							<CustomTableEmptyData/>
					}

				</ModalBody>
				<ModalFooter className="d-flex justify-content-end">
					<Button.Ripple
						color		= "primary"
						onClick		= {() => {
							// setReportTypeActived(data[0])
							setShowModal(!showModal)
						}}
					>
						Tutup
					</Button.Ripple>
				</ModalFooter>
			</Modal>
		</Fragment>
	)
}

export default InputSumber
